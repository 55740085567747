.title{
  position:absolute;
  top:20px;
  left:10%;
  margin: 10px;
  z-index:100;
  width:250px;
}
.logoImg{
  width: 100%;
  height:auto;
}
.modalWidth{
  width:90%;
    top:5%;
    left:5%;
}
.imgModal{
  max-width: 90%;
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) { 
	.logoImg{
    width: 100%;
    height:auto;
    margin-left: 40px;
  }
  .modalWidth{
    width:90%;
    top:5%;
    left:5%;
  }
  .imgModal{
    max-width: 90%;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
	.logoImg{
    width: 100%;
    height:auto;
    margin-left: 40px;
  }
  .modalWidth{
    width:90%;
    top:5%;
    left:5%;
  }
  .imgModal{
    max-width: 90%;
  }
 }

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) { 
	.logoImg{
    width: auto;
    height:100%;
    margin-left: 40px;
  }
  .modalWidth{
    width:50%;
    top:5%;
    left:25%;
  }
  .imgModal{
    max-height: 80vh;
  }
}
.modalHome{
  position: absolute;
  margin:auto;
  z-index: 1000;
  background-color: #2f2f2f;
  border: 1px solid azure;
  border-radius: 5px;
  padding: 20px;
  height:max-content;
}
.cross{
  float: right;
}
.cross:hover{
  color: black;
}
@keyframes growth{
  from{transform: scale(0);}
  to{transform:scale(1)}
}
.anim{
  animation: growth 0.5s ease-in;
}