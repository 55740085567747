.titre{
  display : flex;
  justify-content: space-around;
  align-items: center;
  background-color: #2f2d2d ;
  margin:5px;
  border-radius: 5px;
  border-bottom: 1px solid white;
}
.listeTitre{
  margin: auto;
  width:75%;
}
p{
  margin: 2px;
}
.albumTitle{
  margin: 20px;
  margin-top : 50px;
  font-family: 'Courier New', Courier, monospace;
}
.link{
  position: absolute;
  top: 20px;
  left: 20px;
  font-family: 'Comforter';
}