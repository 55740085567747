.presentation{
  width:75%;
  margin: auto;
}
.imagePresent{
  width:auto;
  height :200px;
  margin-bottom: 20px;
}
.link{
  position: absolute;
  top: 20px;
  left: 20px;
  font-family: 'Comforter';
}
.textPresentation{
  font-family:'Courier New', Courier, monospace ;
}
.portrait{
  width:100px;
  height: 100px;
  filter: grayscale(100%);
}