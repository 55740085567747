@font-face {
	font-family:'Comforter';
	src: url('fonts/Comforter-Regular.ttf');
}
.album{
  display:flex;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  width:100%;
  padding-bottom: 20px;
  padding-top :20px;
  border-bottom: 1px solid white;
  border-radius: 15px ;
  background-color: #2f2d2d ;
  margin-top: 10px;
  margin-bottom: 10px;
}
h2{
  font-family: 'Comforter';
  margin: 0;
}
.titleAlbum{
  font-family: 'Comforter';
  margin: 20px;
  margin-bottom: 0;
}
h1{
  font-family: 'Comforter';
}
.margH1{
  margin-left: 50px;
}
.ecouter{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
.divImage{
  height:40%;
}
.image{
  height: 200px;
  border-radius: 40px;
  padding: 30px;
}
.divTitle{
  padding: 30px;
  width: 50%
}
.description{
  padding: 15px;
  margin-right: 20px;
  margin-left: 20px;
  width:60%;
  border: 1px solid white;
  border-radius: 5px;
  height: 40%;
  font-size: medium;
  font-family: 'Courier New', Courier, monospace;
}
.link{
  position: absolute;
  top: 20px;
  left: 20px;
  font-family: 'Comforter';
}
.flexDir{
  flex-direction: column;
}  
.widthImage{
  width: 60%;
}  

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) { 
	.flexDir{
    flex-direction: column;
  }
  .widthImage{
    width: 60%;
  }  
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
	.flexDir{
    flex-direction: row;
  }
  .widthImage{
    width: 33%;
  }  
 }

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) { 
  .flexDir{
    flex-direction: row;
  }
  .widthImage{
    width: 33%;
  }  
 }

 .scale-up-center {
	-webkit-animation: scale-up-center 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-1-20 19:24:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
