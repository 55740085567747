@font-face {
	font-family:'Comforter';
	src: url('fonts/Comforter-Regular.ttf');
}
li{
  font-family:'Comforter',sans-serif;
  list-style: none;
}
a{
  text-decoration: none;
  color:white;
}
a:hover{
  color: dimgrey;
}
.textNav{
  font-size: 2.7rem;
}
.iconsocial{
  color: white;
}

.flexDirection{
  flex-direction: column-reverse;
}
.menuContainer {
  position: relative;
  width: 100%;
}
.marge{
  position: relative;
  margin-left: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  z-index: 2;
}
.marge.fade-in {
  opacity: 1;
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) { 
	.flexDirection{
    flex-direction: column-reverse;
  }
  .marge{
    margin-left: 0;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
	.flexDirection{
    flex-direction: column-reverse;
  }
  .marge{
    margin-left: 0;
  }
 }

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) { 
	.flexDirection{
    flex-direction: row;
  }
  .marge{
    margin-left: 70px;

  }
}

.imageAccueil{
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  object-fit: cover;
  transition: opacity 1s ease-in-out;
  z-index: 1;
  margin-right: 20px;
}

.imageAccueil.fade-out {
  opacity: 0.4;
}

  