.container {
    width: 100%;
    margin: 0 auto;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .container {
        width: 75%;
    }
}

@media (min-width: 1025px) {
    .container {
        width: 50%;
    }
}
